import {ApolloClient, from, HttpLink, InMemoryCache, ServerError, split} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {getMainDefinition} from '@apollo/client/utilities';
import {AnyAction, Dispatch} from '@reduxjs/toolkit';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import fragment from '../../node_modules/@eon.cz/apollo13-graphql-vyjadrovaci-linka/lib/fragmentTypesV3.json';
import {ErrorResponse as ErrorResponseEnum, redirect} from '../auth/service/LoginService';
import {CommonActionCreator} from '../common/action/CommonAction';
import {BackendEndpoints} from '../common/BackendEndpoints';
import {NotificationsActionCreator} from '../common/components/notifications/actions/NotificationsActions';
import {PageRoute, statusCode} from '../common/constants';
import {GraphQLErrorExtended, GraphQLErrorResolver} from '../common/graphql/GraphQLErrorResolver';
import {NotificationType} from '../models/notification/NotificationModel';
import {RootState} from './store';

const isWindow = typeof window !== 'undefined';

type ServerErrorExtended = ServerError & {
    response: Response;
    result: {
        error: {
            code: string;
        };
    };
    statusCode: number;
};

const errorLink = (dispatch: Dispatch<AnyAction>) =>
    onError(({graphQLErrors, networkError}) => {
        const {addNotification} = NotificationsActionCreator(dispatch);

        if (typeof graphQLErrors === 'object' && graphQLErrors.length > 0) {
            // If there are GraphQL errors, ignore network
            const invalidLogin = (graphQLErrors as unknown as GraphQLErrorExtended[]).reduce((res, error) => (error.code === 'UNAUTHORIZED' ? error : res), {});
            const isInvalidLogin = invalidLogin.code === 'UNAUTHORIZED';
            if (isInvalidLogin) {
                CommonActionCreator(dispatch).logout(apolloClient, ErrorResponseEnum.INVALID_TOKEN);
                return;
            }
            GraphQLErrorResolver.resolveErrors(dispatch, graphQLErrors);
        }
        if (networkError) {
            const result = (networkError as ServerErrorExtended)?.result;
            const code = result?.error?.code;
            if (code === 'INVALID_TOKEN' || code === 'TOKEN_INACTIVITY_EXCEEDED' || code === 'REVOKED_TOKEN') {
                apolloClient.cache.reset().then(() => {
                    redirect({pathname: PageRoute.VSTUP, query: {error: code}}).then(() => {
                        CommonActionCreator(dispatch).logout(apolloClient, code);
                    });
                });
                return;
            }
            if (networkError && 'statusCode' in networkError && statusCode.includes(networkError.statusCode)) {
                apolloClient.cache.reset().then(() => {
                    redirect({pathname: '/'}).then(() => {
                        dispatch({type: 'RESET_APP'});
                        addNotification({
                            type: NotificationType.ERROR,
                            text: <FormattedMessage id="error.network" />,
                        });
                    });
                });
                return;
            }
        }
    });

// Polyfill fetch() on the server (used by @apollo/client)
if (!isWindow) {
    global.fetch = fetch;
}

const createClient = (initialState: RootState | null, dispatch: Dispatch<AnyAction>, backendUrl?: string) => {
    const httpLink = new HttpLink({
        uri: backendUrl ?? `/api/${BackendEndpoints.GRAPHQL}`,
        fetchOptions: {credentials: 'same-origin', fetch},
    });

    const link = isWindow
        ? split(({query}) => {
              const definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && (definition.operation === 'query' || definition.operation === 'mutation');
          }, httpLink)
        : httpLink;

    return new ApolloClient({
        connectToDevTools: isWindow,
        ssrMode: isWindow,
        assumeImmutableResults: false,
        link: from([errorLink(dispatch), link]),
        cache: new InMemoryCache({
            possibleTypes: fragment.possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        adresniMista: {
                            merge: true,
                        },
                    },
                },
            },
        }).restore(initialState || {}),
        defaultOptions: {
            query: {
                fetchPolicy: 'network-only',
            },
        },
    });
};

export let apolloClient: ApolloClient<any>;

export const initApollo = (initialState: RootState | null, dispatch: Dispatch<AnyAction>, backendUrl?: string) => {
    const _apolloClient = apolloClient ?? createClient(initialState, dispatch, backendUrl);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
};

export function useApollo(dispatch: Dispatch<AnyAction>) {
    return useMemo(() => initApollo(null, dispatch, undefined), [dispatch]);
}
