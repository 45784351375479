/* eslint-disable functional/immutable-data */
import {Typography} from '@mui/material';
import {AnyAction, Dispatch} from '@reduxjs/toolkit';
import DOMPurify from 'dompurify';
import {GraphQLError} from 'graphql';
import {NotificationType} from '../../models/notification/NotificationModel';
import {NotificationsActionCreator} from '../components/notifications/actions/NotificationsActions';
import {sanitizeHTML} from '../utils/CommonUtils';

type ValidationError = {
    readonly code: string;
    readonly name: string;
    readonly fields?: string[];
    readonly index?: number;
    readonly relatedIndex?: number;
    readonly type: ValidationErrorType;
    readonly message: string;
};

enum ValidationErrorType {
    CRITICAL,
    WARNING,
}

export type ValidationErrorWithPath = ValidationError & {
    readonly path?: string[];
    readonly errors?: ValidationError[];
};

/**
 * Error resolver function
 *
 * {Dispatch<AnyAction>} dispatch Dispatch function can use (to send notifications using GraphQLErrorResolver.addError, for example)
 * {ValidationErrorWithPath[]} errors Errors to resolve
 * {string} path Path where the errors are, joined to string for convenience
 *
 * @return {ValidationErrorWithPath[]} Errors unresolved by the resolver
 */
type Resolver = (dispatch: Dispatch<AnyAction>, errors: ValidationErrorWithPath[] | undefined, path: string) => ValidationErrorWithPath[];

export type GraphQLErrorExtended = {
    code?: string;
    errors?: ValidationErrorWithPath[];
};

type ResolverMap = {
    [K: string]: Resolver | undefined;
};

export const DEFAULT_RESOLVER_PATH = '';

export const GraphQLErrorResolver = {
    _paths: {} as ResolverMap,

    registerResolver: (path: string, resolver: Resolver) => {
        GraphQLErrorResolver._paths[path] = resolver;
    },

    unregisterResolver: (path: string) => {
        GraphQLErrorResolver._paths[path] = undefined;
    },

    addError: (dispatch: Dispatch<AnyAction>, text: string, errorObject?: any, severity: NotificationType = NotificationType.ERROR) => {
        const errorText = text?.toLocaleString().match(/Chyba 31/g)?.length === 1 ? 'Nebylo zadáno validní IČO. IČO musí obsahovat osm znaků.' : text;
        const saftyTextWithHTML = DOMPurify.sanitize(errorText);
        NotificationsActionCreator(dispatch).addNotification({
            type: severity,
            text: <Typography component="span" color="inherit" dangerouslySetInnerHTML={{__html: sanitizeHTML(saftyTextWithHTML)}} />,
            errorObject,
        });
    },

    resolveErrors: (
        dispatch: Dispatch<AnyAction>,
        errors: ReadonlyArray<GraphQLError & GraphQLErrorExtended & {extensions: {validationErrors?: ValidationError[]; sapErrorMessages?: ValidationError[]}}>,
    ) => {
        /*
         * Process errors first - flatten and divide by path
         */
        const processedErrors: {[key: string]: ValidationErrorWithPath[]} = {};

        const processError = (error: ValidationErrorWithPath) => {
            const path = error.path ? error.path.join('/') : '';

            if (processedErrors[path] === undefined) {
                processedErrors[path] = [];
            }
            processedErrors[path]?.push(error);
        };

        errors.forEach((error) => {
            if (error.code === 'GRAPHQL_NESTED_ERRORS') {
                // Resolve nested errors
                if (error.errors) {
                    error.errors.forEach((nested) => processError({...nested, path: error.path as string[]}));
                }
            } else {
                // Resolve this error
                processError({
                    type: ValidationErrorType.CRITICAL,
                    code: error.code ?? '',
                    name: error.name,
                    message: error.message,
                    path: error.path as string[],
                    errors: error.errors,
                });
            }
        });

        /**
         * Search for handlers and send errors to handlers
         */
        Object.keys(processedErrors).forEach((path) => {
            const pathErrors = processedErrors[path];
            let unresolved = pathErrors;

            const resolver = GraphQLErrorResolver._paths[path];
            if (typeof resolver === 'function') {
                // Have a resolver
                unresolved = resolver(dispatch, pathErrors, path);
            }

            const defaultResolver = GraphQLErrorResolver._paths[DEFAULT_RESOLVER_PATH];
            if (typeof defaultResolver === 'function') {
                // Have default resolver
                unresolved = defaultResolver(dispatch, pathErrors, path);
            }

            unresolved?.forEach((error) => {
                GraphQLErrorResolver.addError(
                    dispatch,
                    typeof error.message === 'string' && error.message.length > 0 ? error.message : 'Chyba při volání graphql',
                    error,
                );
            });
        });
    },
};
